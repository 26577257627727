<template>
  <section class="bg-agrogo-green-2">
    <AuctionHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
    <div class="grid grid-cols-3 bg-agrogo-yellow-2 h-20 md:h-24">
      <div class="flex h-auto w-auto items-center justify-end">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >08</span
        >
      </div>
      <div
        class="col-span-2 flex flex-col items-start justify-center ml-5 md:ml-8"
      >
        <span class="font-nexa font-black text-base md:text-2xl mb-3 text-left"
          >Documentación
          <span class="font-normal hidden lg:inline-flex"> Legal</span>
        </span>
        <div class="flex items-center space-x-4 md:space-x-7 md:text-base">
          <span class="icon-punto text-agrogo-green-3"></span>
          <span class="icon-punto text-white"></span>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center bg-white">
      <div class="mt-6 mb-2 lg:mt-11 lg:mb-7">
        <img
          class="w-16 lg:w-28 h-full"
          src="@/assets/images/tyc.svg"
          alt="terminos_y_condiciones"
        />
      </div>
      <div class="mb-6 lg:mb-16">
        <span
          class="font-nexa font-black text-base text-agrogo-green-2 lg:text-3xl"
          >Términos y <span class="font-normal"> Condiciones</span></span
        >
      </div>
    </div>
    <div
      class="flex items-center justify-start lg:justify-center bg-agrogo-yellow-2 h-10 lg:h-12"
    >
      <span
        class="font-nexa font-normal text-agrogo-green-2 pl-8 lg:pl-0 text-sm lg:text-2xl"
        >Extraemos puntos
        <span class="font-black"> relevantes para ti</span></span
      >
    </div>
    <div
      class="grid grid-cols-1 gap-3 my-6 ml-8 mr-6 md:mx-12 lg:mx-32 xl:mx-60 md:mt-12 lg:mt-20 md:mb-12"
    >
      <div class="flex items-start">
        <span class="icon-desplegar drop-down-icon pt-1"></span>
        <p
          class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non hendrerit
          scelerisque.
        </p>
      </div>
      <div class="flex items-start">
        <span class="icon-desplegar drop-down-icon pt-1"></span>
        <p
          class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non hendrerit
          scelerisque.
        </p>
      </div>
      <div class="flex items-start">
        <span class="icon-desplegar drop-down-icon pt-1"></span>
        <p
          class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non hendrerit
          scelerisque.
        </p>
      </div>
      <div class="flex items-start">
        <span class="icon-desplegar drop-down-icon pt-1"></span>
        <p
          class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non hendrerit
          scelerisque.
        </p>
      </div>
      <div class="flex items-start">
        <span class="icon-desplegar drop-down-icon pt-1"></span>
        <p
          class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non hendrerit
          scelerisque.
        </p>
      </div>
    </div>
    <div class="md:mx-12 lg:mx-32 xl:mx-60">
      <div class="w-full h-3 bg-agrogo-green-3 md:h-5"></div>
      <div class="h-auto relative">
        <div
          class="flex w-1/2 bg-agrogo-yellow-1 h-3 md:h-5 absolute -top-1 right-0"
        ></div>
      </div>
    </div>

    <div
      class="flex justify-start items-start bg-white h-auto pl-8 pr-10 lg:pl-14 lg:pr-16 py-8 md:mx-12 lg:mx-32 xl:mx-60 lg:mb-12"
    >
      <div class="container-terms-conditions scroll-color-step7 pr-1">
        <div
          class="font-dinpro text-agrogo-black-1 text-sm text-left opacity-80 md:text-base text-info-conditions"
        >
          Este documento describe los términos y condiciones generales (los
          "Términos y Condiciones Generales") aplicables al uso de los servicios
          ofrecidos por JUAN PABLO BEDOYA GUTIÉRREZ, (AGRO GO) NIT
          1.037.574.109-8, ("los Servicios") dentro de los sitio
          www.AGROGO.com.co (el "Sitio"). Cualquier persona (en adelante
          "Usuario" o en plural "Usuarios") que desee acceder y/o usar el sitio
          o los servicios podrá hacerlo sujetándose a los Términos y Condiciones
          Generales, junto con todas las demás políticas y principios que rigen
          el Sitio y que son incorporados al presente por referencia. CUALQUIER
          PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS
          CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE
          DE UTILIZAR EL SITIO Y/O LOS SERVICIOS. El Usuario debe leer, entender
          y aceptar todas las condiciones establecidas en los Términos y
          Condiciones Generales así como en los demás documentos incorporados a
          los mismos por referencia. Capacidad: Los Servicios sólo están
          disponibles para personas que tengan capacidad legal para contratar.
          Publicación de anuncios: En todos los anuncios, el Usuario deberá
          declarar, brindar y publicar toda información relativa el bien
          ofrecido de manera exacta, precisa y verdadera y asume el compromiso
          de actualizarla conforme resulte necesario. AGRO GONO se
          responsabiliza por la certeza de la información provista por sus
          Usuarios, tanto la relativa a sus datos personales como al bien
          ofrecido. Los Usuarios garantizan y responden, en cualquier caso, de
          la veracidad, exactitud, vigencia y autenticidad de todos los datos
          ingresados. En todos los casos, los bienes deberán anunciarse en las
          categorías y subcategorías apropiadas. Los anuncios podrán incluir
          textos descriptivos, gráficos, fotografías, videos y otros contenidos
          y condiciones pertinentes para la venta del bien, siempre que no
          violen ninguna ley, disposición de este acuerdo o demás políticas del
          Sitio. El bien ofrecido por el Usuario debe ser exactamente descrito
          en cuanto a sus condiciones y características relevantes. En el caso
          de que se incluya una fotografía y/o video, esta deberá corresponder
          específicamente al bien que se ofrece. Se entiende y presume que
          mediante
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-start lg:justify-center bg-agrogo-yellow-2 h-7 lg:h-12"
    >
      <span
        class="font-nexa font-normal text-agrogo-green-2 pl-8 lg:pl-0 text-sm lg:text-2xl"
        >Verificación <span class="font-black"> de Usuario</span></span
      >
    </div>
    <div class="flex items-center justify-center">
      <div
        class="grid grid-cols-2 px-10 md:bg-agrogo-green-5 md:max-w-484 md:h-14 my-8 place-content-center"
      >
        <div class="flex items-center justify-start">
          <a class="font-nexa text-white text-left text-sm lg:text-base"
            >Cédula</a
          >
        </div>
        <div class="flex items-center">
          <input
            id="identification"
            name="identification"
            type="number"
            class="input-standard-transparent pl-4 md:h-8 lg:h-8 md:max-w-236"
          />
        </div>
      </div>
    </div>

    <div class="flex space-x-2 justify-center pb-10 px-6">
      <div class="flex flex-col space-y-2 cursor-pointer">
        <img
          class="img-identification"
          src="@/assets/images/cargaranverso.jpg"
        />
        <div class="flex flex-row items-center px-2 space-x-3">
          <a
            class="icon-upload text-agrogo-yellow-1 text-sm md:text-base md:ml-10"
          ></a>
          <a
            class="text-agrogo-gray-1 text-left text-xs font-dinpro md:text-base"
          >
            Cargar anverso
            <input
              id="file"
              ref="fileInput"
              type="file"
              class="hidden"
              name="file0"
              accept=".jpg, .png, .jpeg, video/*"
            />
          </a>
        </div>
      </div>
      <div class="flex flex-col space-y-2 cursor-pointer">
        <img
          class="img-identification"
          src="@/assets/images/cargarreverso.jpg"
        />
        <div class="flex flex-row items-center px-2 space-x-3">
          <a
            class="icon-upload text-agrogo-yellow-1 text-sm md:text-base md:ml-10"
          ></a>
          <a
            class="text-agrogo-gray-1 text-left text-xs font-dinpro md:text-base"
          >
            Cargar reverso
            <input
              id="file"
              ref="fileInput2"
              type="file"
              class="hidden"
              name="file0"
              accept=".jpg, .png, .jpeg, video/*"
            />
          </a>
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-start md:justify-center space-x-2 px-10 py-2 md:mb-2"
    >
      <input type="checkbox" class="custom-checkbox-test" />
      <label class="font-nexa text-agrogo-gray-1 text-xs text-left lg:text-lg">
        <span class="text-agrogo-yellow-2">*</span>
        Acepto todos los términos y condiciones
      </label>
    </div>

    <div class="flex items-center justify-start md:justify-center px-10 mb-8">
      <a class="text-agrogo-gray-1 text-xs font-nexa md:text-sm"
        >Datos para USO INTERNO DE
        <span class="font-black md:text-sm">AGRO GO</span></a
      >
    </div>
    <!-- botones de navegacion -->
    <div
      class="bg-agrogo-yellow-1 h-14 md:h-20 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button @click="previus()" type="button" class="btn-primary">
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button @click="next()" type="button" class="btn-primary">
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
        ></span>
      </button>
    </div>
  </section>
</template>
<script>
import AuctionHeader from "@/components/header/HeaderAuction";
export default {
  components: {
    AuctionHeader,
  },
};
</script>
